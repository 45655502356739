//Header
header {
  @include angle(after);
  z-index: 9999;
  height: 800px;
  background: #c9ded5;
  background: -moz-linear-gradient(top, #c9ded5 35%, #bab0f7 100%);
  background: -webkit-linear-gradient(top, #c9ded5 35%,#bab0f7 100%);
  background: linear-gradient(to bottom, #c9ded5 35%,#bab0f7 100%);
  margin-bottom: 320px;
  padding-top: 350px;
  @include mq(tablet-big) {
    height: auto;
    margin-bottom: 10%;
    padding-top: 0; }

  .header-top {
    @include clearfix;
    position: fixed;
    height: 110px;
    top: 70px;
    width: 100%;
    left: 0;
    padding-left: 70px;
    z-index: 9999;
    @include transition(all 0.3s);

    &.smaller {
      height: 80px;
      background-color: #C9DED5;
      top: 0; }

    @include mq(laptop) {
      margin-bottom: 120px; }

    @include mq(tablet-big) {
      padding-left: 10px;
      position: relative; }

    @include mq(tablet) {
      margin-bottom: 100px;
      height: auto;
      padding-left: 0;
      top: 50px; }

    @include mq(phone) {
      padding-top: 40px;
      top: 40px; }

    > div {
      @extend .col-md-6;
      padding: 0; }

    .logo-holder {
      width: 32%;

      @include mq(tablet-big) {
        width: 100%; }

      a {
        display: block;
        width: 330px;
        text-align: center;

        @include mq(tablet) {
          margin: 0 auto; }

        @include mq(phone-big) {
          width: auto; } }

      img {
        display: inline-block;
        max-width: 100%;
        height: 103px;
        @include transition(.3s );

        @include mq(tablet) {
          display: block;
          margin: 0 auto; }

        @include mq(phone) {
          width: 280px;
          height: auto; } } }

    .nav-holder {
      width: 68%;

      @include mq(tablet-big) {
        width: 100%; } }

    nav {

      ul {
        @include clearfix;
        padding-top: 40px;
        @include transition(all .3s);

        @include mq(tablet-big) {
          padding-top: 30px; }

        @include mq(tablet) {
          text-align: center; }

        li {
          float: left;
          margin-right: 70px;

          @media only screen and (max-width: 1800px) {
            margin-right: 45px; }

          @include mq(desktop) {
            margin-right: 35px; }

          @include mq(tablet) {
            float: none;
            display: inline-block; }

          @include mq(phablet) {
            display: block;
            margin-bottom: 8px;
            margin-right: 0; }

          &:last-child {
            margin-right: 0; }

          a {
            display: block;
            font-size: 22px;
            font-weight: 700;
            text-transform: uppercase;
            color: $black;
            @include transition(all .2s ease-out);

            @include mq(tablet) {
              font-size: 19px; }

            @include mq(phone) {
              font-size: 18px; }

            &:hover {
              color: #727272; } } } } } }

  .hero {
    padding-left: 60px;

    @include mq(tablet) {
      padding-left: 30px; }

    @include mq(phablet) {
      padding: 0; }

    p {
      font-size: 100px;
      color: $black;
      font-weight: 700;
      line-height: 1.3;
      @include mq(laptop) {
        line-height: 1.1; }

      @include mq(tablet) {
        font-size: 67px; }

      @include mq(tablet) {
        font-size: 48px; }

      @include mq(phone-big) {
        font-size: 44px; }

      @media only screen and (max-width: 376px) {
        font-size: 41px; }

      @include mq(phone-small) {
        font-size: 38px; }

      @include mq(phone-smallest) {
        font-size: 34px; }

      b {
        color: #656565;
        font-weight: 700; } } } }

.header-top.smaller {

  .logo-holder {

    img {
      height: 60px;
      margin-top: 13px; } }

  nav {
    ul {
      padding-top: 30px; } } }
