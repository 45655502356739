// Footer
footer {
  position: relative;
  //padding: 38px 0 60px
  background: #656565;
  color: #fff;
  @include angle(before);
  padding: 60px 0 100px;

  &:before {
    height: 250%; }

  .webmo-info {
    position: absolute;
    top: -130px;
    left: 90px;

    @include mq(tablet-big) {
      top: -90px;
      left: 40px; }

    @include mq(tablet) {
      position: relative;
      text-align: center;
      top: 0;
      left: 0; }

    p {
      font-size: 22px;
      line-height: 1.6;
      margin-bottom: 30px;
      font-weight: 300; } }

  .footer-social {
    text-align: center;
    margin-bottom: 70px;

    a {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 60px;
      background: #fff;
      border-radius: 50%;
      margin: 0 20px;

      i {
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        color: #727272;
        font-size: 30px; } } }

  @include mq(tablet) {
    height: auto; }

  .footer-nav-wrap {
    @include clearfix;
    padding-left: 150px;

    @include mq(tablet-big) {
      padding-left: 0; }

    .footer-nav-holder {
      float: left;
      width: 25%;
      font-size: 20px;
      color: #fff;

      @include mq(tablet) {
        width: 50%;
        font-size: 17px;
        text-align: center;
        height: 310px; }

      @include mq(phone-small) {
        width: 100%;
        height: auto;
        margin-bottom: 30px; }

      p {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 23px; }

      a {
        display: block;
        margin-bottom: 23px;
        color: #fff;
        @include transition(all .1s linear);

        @include mq(phone-small) {
          margin-bottom: 15px; }

        &:hover {
          color: #d5d4d4; } } } }

  .footer-copyright {
    text-align: center;
    font-size: 20px;

    @include mq(tablet) {
      font-size: 16px; } } }
