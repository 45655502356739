//General settings
body {
  font-family: 'Open-Sans', sans-serif;
  background: #fff;
  font-weight: 400; }

.content {
  @extend .container; }


.content-full {
  @extend .container-fluid;
  padding: 0 145px;

  @media only screen and (max-width: 1600px) {
    padding: 0 75px; }

  @include mq(laptop) {
    padding: 0 50px; }

  @media only screen and (max-width: 1080px) {
    padding: 0 30px; }

  @include mq(phablet) {
    padding: 0 20px; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.wrapper {
  overflow: hidden; }

.services-wrap .services-bottom.tab-hidden {
  display: none; }

.services-bottom {
  display: block;
  -webkit-animation: fadeIn .5s;
  animation: fadeIn .5s; }

//Slick slider settings
.slick-slider {
  background: url(plugins/slick/ajax-loader.gif);
  .slick-list {
    opacity: 0;
    position: absolute; }

  &.slick-initialized {
    background: none;

    .slick-list {
      opacity: 1;
      position: relative;
      transition: opacity 0.2s ease-in; } } }

.home-slides {
  visibility: hidden; }

.slick-initialized {
  visibility: visible; }
