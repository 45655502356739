//Homepage
.lang-bar {
  position: absolute !important;
  right: 20px;
  top: -40px;

  @include mq(tablet) {
    top: -130px;
    right: 10px; }
  a {
    display: inline-block !important;
    color: #656565 !important; } }

// Hero
.text-boxes-wrap {
  @include clearfix;
  padding: 120px 0 160px;

  @include mq(tablet-big) {
    padding-bottom: 100px; }

  @include mq(tablet) {
    padding-bottom: 40px; }

  @include mq(phone-big) {
    padding-top: 60px; }

  .white-block-holder {
    @include clearfix;

    .white-block-text {
      float: left;
      width: 30%;
      margin-right: 20%;

      @include mq(tablet-big) {
        width: 100%;
        float: none; }

      h4 {
        font-size: 33px;
        color: $black;
        margin-bottom: 20px;
        font-weight: 700;

        @include mq(tablet) {
          font-size: 26px; } }

      p {
        font-size: $reg-font;
        color: $black;
        line-height: 1.6;
        font-weight: 400;
        margin-bottom: 40px;

        @include mq(tablet) {
          font-size: 17px;
          margin-bottom: 30px; }

        &:last-child {
          margin-bottom: 0; }

        b {
          font-weight: 700; } } }

    .white-block-img {
      float: right;
      width: 50%;

      @include mq(tablet-big) {
        width: 100%;
        float: none;

        img {
          width: 550px;
          display: block;
          margin: 0 auto 20px; } }

      img {
        max-width: 100%; } } }

  //.box-holder
  //  @extend .col-md-4
  //  position: relative
  //
  //  +mq(phone-big)
  //    padding: 0
  //
  //  &:after
  //    content: ''
  //    display: block
  //    width: 1px
  //    height: 100%
  //    background: $pink
  //    +position(absolute, 0 0 null null)
  //
  //    +mq(tablet)
  //      display: none
  //
  //  &:last-child:after
  //    display: none
  //
  //  .box-content
  //    padding: 0 40px 0 65px
  //
  //    +mq(laptop)
  //      padding: 0 30px 0 35px
  //
  //    +mq(tablet-big)
  //      padding: 0
  //
  //    +mq(tablet)
  //      margin-bottom: 40px
  //
  //    h5
  //      font-size: 33px
  //      font-weight: 700
  //      margin-bottom: 20px
  //      color: $black
  //
  //      +mq(tablet)
  //        font-size: 27px
  //        margin-bottom: 15px
  //
  //    p
  //      font-size: $reg-font
  //      line-height: 1.8
  //      margin-bottom: 40px
  //
  //      +mq(tablet)
  //        font-size: 17px
  //        line-height: 1.4
  //        margin-bottom: 20px
  //
  //    a
  //      display: block
  //      font-size: $reg-font
  //      font-weight: 700
  //      color: $pink
  //      +transition(all .1s linear)
  //
  //      +mq(tablet)
  //        font-size: 17px
  //
  //      &:hover
 }  //        color: #a374a6

//Services wrap
.services-wrap {
  position: relative;
  overflow: hidden;
  @include angle(before, true);
  background: #000;

  #services {
    position: absolute;
    bottom: 840px; }

  .services-top {
    height: 50px;
    position: relative;
    margin-bottom: 1200px;
    width: 100%;
    @include angle(after);
    background: #6EE6A6;

    &:after {
      height: 1000px; }

    @include mq(tablet-small) {
      margin-bottom: 800px; }

    @include mq(phone-big) {
      margin-bottom: 600px; } }

  .services-bottom.on-top {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 0; }

  .services-bottom {

    &.first {
      background: #BDCCD4; }

    &.second {
      background: #D0A2D2; }

    &.third {
      background: #323232;

      h4, p {
        color: #fff; } }

    &.fourth {
      background: #656565;

      h4, p {
        color: #fff; } }

    &.fifth {
      background: #BABABA; } }

  .services-top {
    -webkit-animation: none;
    animation: none; }

  .services-bottom {
    height: 200px;
    @include angle(before);
    background: #000;
    position: relative;
    z-index: 1;

    &:before {
      height: 480px;
      top: -1px;

      @include mq(tablet-big) {
        height: 280px; } }

    @include mq(tablet-small) {
      height: auto;
      padding-top: 30px; } }

  .services-bg {
    @include position(absolute, 50px null null 0);
    height: 1200px;
    background: url(images/services-bg.jpg) no-repeat left center fixed;
    background-size: cover;
    width: 100%;

    @include mq(tablet-big) {
      top: 0;
      background-image: url(images/services-bg1-min.jpg);
      background-attachment: scroll; } }

  .services-top-text {
    @include position(absolute, -130px null null 65px);

    @include mq(laptop) {
      left: 65px;
      top: -70px;
      width: 70%; }

    @include mq(laptop-small) {
      left: 30px;
      top: -40px; }

    @include mq(tablet) {
      position: relative;
      top: 0;
      left: 0;
      padding-left: 20px;
      padding-bottom: 30px;
      width: 80%; }

    @include mq(phone-big) {
      width: 100%; }

    h4 {
      font-size: 33px;
      color: $black;
      margin-bottom: 20px;
      font-weight: 700;

      @include mq(tablet) {
        font-size: 26px; } }

    p {
      font-size: $reg-font;
      color: $black;
      line-height: 1.6;
      font-weight: 400;

      br {
        @include mq(laptop) {
          display: none; } }

      @include mq(laptop) {
        line-height: 1.5; }

      @include mq(tablet-big) {
        font-size: 17px; }

      @include mq(phone-big) {
        font-size: 16px;
        line-height: 1.3; }

      @include mq(phone-smallest) {
        font-size: 15px; } } }

  .services-nav {

    p {
      @include angle(before);
      height: 0;

      &:before {
        height: 70px;
        color: #fff;
        font-size: 27px;
        font-weight: 700;
        padding-left: 65px;
        line-height: 69px;

        @include mq(tablet) {
          height: 46px;
          font-size: 22px;
          line-height: 45px;
          padding-left: 40px; }

        @include mq(phablet) {
          font-size: 19px; }

        @include mq(phone-small) {
          font-size: 18px; } }

      &:hover:before {
        text-decoration: underline;
        cursor: pointer; }

      &.first:before {
        content: 'Design';
        background: #BDCCD4;
        margin-top: -70px;

        @include mq(tablet) {
          margin-top: -46px; } }

      &.second:before {
        content: 'Development';
        background: #D0A2D2;
        margin-top: -138px;
        margin-left: 20%;

        @include mq(tablet) {
          margin-top: -91px;
          margin-left: 5%; } }

      &.third:before {
        content: 'Digital Marketing';
        background: #323232;
        margin-top: -206px;
        margin-left: 40%;

        @include mq(tablet) {
          margin-top: -136px;
          margin-left: 10%; } }

      &.fourth:before {
        content: 'Consulting Services';
        background: #656565;
        margin-top: -272px;
        margin-left: 60%;

        @include mq(tablet) {
          margin-top: -181px;
          margin-left: 15%; } }

      &.fifth:before {
        content: 'Hosting';
        background: #BABABA;
        margin-top: -340px;
        margin-left: 80%;

        @include mq(tablet) {
          margin-top: -226px;
          margin-left: 20%; } } } } }

.is-lang {

  .services-nav {

    p {

      &.first:before {
        content: 'Hönnun'; }

      &.second:before {
        content: 'Forritun'; }

      &.third:before {
        content: 'Stafræn markaðssetning'; }

      &.fourth:before {
        content: 'Ráðgjöf'; }

      &.fifth:before {
        content: 'Hýsing'; } } } }

// White section
.white-wrap {
  @include angle(after);
  background: #fff;
  padding: 130px 0 55px;

  @include mq(tablet-big) {
    padding-top: 90px;
    padding-bottom: 10px; }

  @include mq(tablet) {
    padding-top: 50px; }

  .team-holder {

    > p {
      font-size: 20px;
      line-height: 1.6;
      padding: 0 10%;
      text-align: center;
      margin-bottom: 60px;

      @include mq(tablet) {
        padding: 0 5%;
        font-size: 24px;
        margin-bottom: 50px; }

      @include mq(phone-big) {
        padding: 0;
        font-size: 20px; } }

    .team-members {
      @include clearfix;

      .team-member-holder {
        @extend .col-md-4;
        text-align: center;

        @include mq(tablet-big) {
          width: 80% !important;
          margin: 0 auto 45px;
          float: none; }

        @include mq(tablet-small) {
          width: 90% !important; }

        @include mq(phone-big) {
          width: 100% !important; }

        &:after {
          content: '';
          @include position(absolute, 120px 0 null null);
          width: 2px;
          height: 340px;
          background: #ccc;

          @include mq(tablet-big) {
            display: none; } }

        &:last-of-type:after {
          display: none; }

        .team-member {
          position: relative;
          padding: 0 20px;

          @include mq(phablet) {
            padding: 0; } }

        .member-pic {
          margin: 0 auto 40px;
          width: 240px;
          height: 240px;
          border-radius: 50%;
          background: #E7E7E7;
          border: 2px solid #ccc;
          overflow: hidden;

          @include mq(phone-big) {
            width: 200px;
            height: 200px;
            margin-bottom: 30px; } }

        h4 {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 15px; }

        h5 {
          font-size: 19px;
          margin-bottom: 30px;
          color: rgba(0,0,0,0.7);
          font-weight: 300; }

        p {
          text-align: left;
          font-size: 18px;
          line-height: 1.6; } } } } }

//Black section
.black-wrap {
  padding: 350px 0;
  background: url(images/burst-pic.jpg) no-repeat left center fixed;
  background-size: cover;

  @include mq(tablet-big) {
    background-image: url(images/burst-pic1-min.jpg);
    background-attachment: scroll; }

  @include mq(tablet-big) {
    padding: 260px 0; }

  .content-full {
    @include clearfix; }

  .black-wrap-text {
    float: right;
    width: 30%;

    @include mq(laptop) {
      width: 40%; }

    @include mq(tablet-big) {
      width: 60%; }

    @include mq(tablet) {
      width: 100%; }

    h4 {
      font-size: 33px;
      color: #fff;
      margin-bottom: 20px;
      font-weight: 700;

      @include mq(tablet) {
        font-size: 26px; } }

    p {
      font-size: $reg-font;
      color: #fff;
      line-height: 1.6;
      font-weight: 400;
      margin-bottom: 40px;

      @include mq(tablet) {
        font-size: 17px;
        line-height: 1.6;
        margin-bottom: 30px; }

      &:last-child {
        margin-bottom: 0; }

      b {
        font-weight: 700; } } } }

//Contact section
.contact-wrap {
  @include angle(before);
  background: #fff;
  padding: 105px 0 310px;

  @include mq(tablet-big) {
    padding-bottom: 180px; }

  @include mq(phablet) {
    padding-bottom: 100px; }

  &:before {
    height: 100%; }

  h5 {
    color: #989898;
    text-align: center;
    margin-bottom: 35px;
    font-size: 33px;
    font-weight: 300; }

  .contact-holder {
    @include clearfix;
    width: 1350px;
    margin: 0 auto;

    @include mq(laptop) {
      width: 96%; }

    @include mq(tablet-big) {
      width: 85%; }

    .form-holder {
      float: left;
      width: 43%;
      margin-right: 14%;

      @include mq(tablet) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 50px; } }

    .map-wrap {
      float: left;
      width: 43%;
      #map-holder {
        height: 430px;
        background-color: #ccc; }

      @include mq(tablet) {
        width: 100%; } }

    form {
      @include clearfix;

      .input-wrap {
        margin-bottom: 30px;

        input {
          width: 100%;
          height: 75px;
          font-size: 24px;
          font-weight: 300;
          color: #656565;
          border: 4px solid #656565;
          background: #fff;
          padding: 0 22px;
          outline: 0;
          @include transition(all .2s ease-out);

          @include mq(tablet-big) {
            height: 55px;
            line-height: 55px;
            font-size: 20px; }

          @include mq(phablet) {
            height: 40px;
            line-height: 40px;
            font-size: 17px;
            font-weight: 400; }

          &:active, &:focus {
            @include input-hover; } } }

      .textarea-wrap {
        width: 100%;
        margin-bottom: 30px;

        textarea {
          width: 100%;
          height: 220px;
          font-size: 24px;
          font-weight: 300;
          font-family: 'Open-Sans', sans-serif;
          color: #656565;
          background: #fff;
          border: 4px solid #656565;
          padding: 22px;
          outline: 0;
          resize: none;
          @include transition(all .2s ease-out);

          @include mq(tablet-big) {
            height: 150px;
            font-size: 20px;
            padding: 20px; }

          @include mq(phablet) {
            height: 130px;
            font-size: 17px;
            font-weight: 400; }

          &:active, &:focus {
            @include input-hover; } } }

      .submit-btn {
        display: block;
        width: 240px;
        height: 80px;
        margin: 0 auto;
        font-size: 24px;
        font-weight: 700;
        font-family: 'Open-Sans', sans-serif;
        color: #656565;
        background: #fff;
        border: 4px solid #656565;
        outline: 0;
        @include transition(all .2s ease-out);
        cursor: pointer;

        @include mq(tablet-big) {
          height: 55px;
          line-height: 50px;
          font-size: 20px; }

        @include mq(phablet) {
          height: 40px;
          line-height: 30px;
          font-size: 17px;
          font-weight: 400; }

        &:hover {
          @include input-hover; } } } } }
